<template>
	<div class="">
		<div class="pa-10-pc bg-base">
			<div
				class="container tab justify-space-between"
			>
				<div class="pa-10-20-m bg-base-m">
					<ul>
						<li
							v-for="(tab, t_index) in items_tab"
							:key="'tab_' + t_index"

							class="inline-block mr-10"
						>
							<button
								class="font-weight-bold size-px-13"
								:class="tab.code == b_code ? 'color-primary' : ''"
								@click="toBbs(tab.code)"
							>{{ tab.name }}</button>
							<span
								v-if="items_tab.length > 0 && t_index < items_tab.length - 1"
							> |</span>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div
			class="container"
		>
			<NoticeList
				v-if="b_code == b_code_notice"
				:user="user"
				:codes="codes"

				@onLoading="$emit('onLoading')"
				@offLoading="$emit('offLoading')"
				@setNotify="setNotify"
			></NoticeList>
			<QnAList
				v-if="b_code == b_code_qna"

				:user="user"
				:codes="codes"

				@onLoading="$emit('onLoading')"
				@offLoading="$emit('offLoading')"
				@setNotify="setNotify"
				@push="push"
			></QnAList>

			<FaqList
				v-if="b_code == b_code_faq"

				:user="user"
				:codes="codes"

				@onLoading="$emit('onLoading')"
				@offLoading="$emit('offLoading')"
				@setNotify="setNotify"
				@push="push"
			></FaqList>
			<ReviewList
				v-if="b_code == b_code_after"

				:user="user"
				:codes="codes"

				@onLoading="$emit('onLoading')"
				@offLoading="$emit('offLoading')"
				@setNotify="setNotify"
			></ReviewList>
		</div>
	</div>
</template>

<script>

	import NoticeList from "./NoticeList";
	import QnAList from "./QnAList";
	import ReviewList from "./ReviewList";
	import '@toast-ui/editor/dist/toastui-editor-viewer.css';
	import FaqList from "./FaqList";

	export default {
		name: 'CustomerCenter'
		,components: {FaqList, ReviewList, QnAList, NoticeList}
		,props: ['user', 'codes']
		,data: function(){
			return {
				program: {
					name: '고객센터'
					, top: false
					, title: true
					, bottom: true
					, is_container: true
				}
				, b_code: this.$route.params.b_code ? this.$route.params.b_code : this.b_code_notice
				, Viewer: null
				, b_code_notice: 'b_notice'
				, b_code_qna: 'b_qna'
				, b_code_after: 'b_after'
				, b_code_faq: 'b_faq'
				, items_tab: [
					{ name: '공지사항', code: 'b_notice'}
					, { name: '1:1문의', code: 'b_qna'}
					, { name: 'FAQ', code: 'b_faq'}
					, { name: '상품후기', code: 'b_after'}
				]
			}
		}
		,methods: {
			setNotify: function({ type, message }){
				this.$bus.$emit('notify', { type: type, message: message })
			}
			,toBbs: function(b_code){
				this.$bus.$emit('to', {name: 'CustomerCenter', params: { b_code: b_code }})
			}
			,push: function( name, params){
				this.$bus.$emit('to', name, { params: params })
			}
		}
		,created() {
			this.$bus.$emit('onLoad', this.program)
		}
	}

</script>

<style>
	.tab .on { background-color: #333; color: #eee}
</style>