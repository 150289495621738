<template>
	<div class="mt-30-pc ">

		<search
			:program="program"
			:search="item_search"
			:option="item_search_option"

			@click="getSearch"
			@toItem="toItem"
		>
		</search>

		<template
			v-if="items.length > 0"
		>
			<table class="table hide-m">
				<colgroup>
					<col width="80px" />
					<col width="auto" />
					<col width="180px" />
					<col width="180px" />
				</colgroup>
				<thead>
				<tr>
					<th>No</th>
					<th>제목</th>
					<th>답변 여부</th>
					<th>등록일</th>
				</tr>
				</thead>
				<tbody>
					<template
						v-for="(item, index) in list_items"
					>
						<tr
							:key="'item_' + index"
							@click="toDetail(item)"
						>
							<td :rowspan="item.is_on ? 3 : 1">{{ item.no }}</td>
							<td class="td-left " :class="{'bg-base': item.is_on}">
								<div class="justify-space-between items-center">
									<div class="font-weight-bold">{{ item.b_title }}</div>
									<v-icon v-if="item.is_on">mdi mdi-chevron-up</v-icon>
									<v-icon v-else>mdi mdi-chevron-down</v-icon>
								</div>
							</td>
							<td :class="'color-' + item.is_answer_color">{{ item.is_answer_name}}</td>
							<td>{{ item.wDate }}</td>
						</tr>
						<tr
							v-if="item.is_on"
							:key="'item_ask_' + index"
						>
							<td colspan="3" class="td-left" :inner-html.prop="'문의 내용<br/><br/>' + item.b_contents | nl2br"></td>
						</tr>
						<tr
							v-if="item.is_on"
							:key="'item_answer_' + index"
						>
							<td colspan="3" class="td-left" :inner-html.prop="item.b_answer ? '답변 내용 <br/><br/>' + item.b_answer : '-' | nl2br"></td>
						</tr>
					</template>
				</tbody>
			</table>

			<ul class="hide-pc mt-10 top-line">
				<li
					v-for="item in list_items"
					:key="'item_' + item.uid"
					class="under-line"
				>
					<div
						class="pa-10 justify-space-between items-center "
						:class="item_content != item.uid ? 'bg-white' : 'bg-base'"
						@click="item_content != item.uid ? item_content = item.uid : item_content = null"
					>
						<span>[<span :class="item.is_answer_color">{{ item.is_answer_name }}</span>] {{ item.b_title }}</span>
						<v-icon
							v-if="item_content != item.uid"
						>mdi mdi-menu-down</v-icon>
						<v-icon
							v-else
						>mdi mdi-menu-up</v-icon>
					</div>
					<div
						v-if="item_content == item.uid"
					>
						<div class="pa-10 justify-space-between items-center under-line-dashed">
							<span>{{ item.m_name }}</span>
							<span>{{ item.wDate }}</span>
						</div>
						<Viewer
							v-if="item.b_contents"
							:initialValue="item.b_contents"
							class="pa-10"
						/>
						<hr class="under-line-dashed" />
						<Viewer
							v-if="item.b_answer"
							:initialValue="item.b_answer"
							class="pa-10"
						/>
					</div>
				</li>
			</ul>

			<Pagination
				:program="program"
				:align="'center'"
				:options="item_search"

				@click="getSearch"

				class="mt-auto pa-10"
			></Pagination>
		</template>
		<Empty
			v-else
		>
		</Empty>
	</div>
</template>

<script>

import '@toast-ui/editor/dist/toastui-editor-viewer.css';
import { Viewer } from "@toast-ui/vue-editor";
import Pagination from "../../components/Pagination";
import Search from "../Layout/Search";
import Empty from "../Layout/Empty";

export default {
	name: 'QnAList'
	,props: ['user']
	,components: {Empty, Search, Pagination, Viewer}
	,data: function() {
		return {
			program: {
				name: '1:1문의'
				, top: false
				, title: true
				, bottom: true
				, is_container: true
			}
			,item_search:{
				b_code: this.$route.params.b_code
				, page: 1
				, list_cnt: 10
				, tCnt: 0
				, search_type: 'b_title'
				, search_value: ''
				, m_id: this.user.member_id ? this.user.member_id : 'none'
			}
			, item_search_option: {
				is_cnt: true
				, is_item: true
				, search_type: [{ column: 'b_title', name: '제목'}]
			}
			,items: [

			]
			,item_content: null
		}
	}

	,computed: {
		list_items: function(){
			return this.items.filter( (item, index) => {
				item.no = this.item_search.tCnt - (this.item_search.page * this.item_search.list_cnt - this.item_search.list_cnt) - index
				if(item.b_answer){
					item.is_answer_color = 'font-weight-bold'
					item.is_answer_name = '답변 완료'
				}else{
					item.is_answer_color = ''
					item.is_answer_name = '답변 대기'
				}
				return item
			})
		}
	}
	,methods: {
		getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					,url: 'board/getBbsList'
					,data: this.item_search
				})
				if(result.success){
					this.items = result.data.result
					this.$set(this.item_search, 'tCnt', result.data.tCnt)
					this.$bus.$emit('notify', { type: 'success', message: result.message })
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getSearch: function(page){
			if(page){
				this.item_search.page = page
			}
			this.getData()
		}
		,toItem: function(){
			this.$bus.$emit('to', { name: 'BbsItem', params: { b_code: this.item_search.b_code }})
		}
		, toDetail: function(item){
			this.$set(item, 'is_on', !item.is_on)
		}
	}
	,created() {
		console.log('in noticelist ')
		this.$bus.$emit('onLoad', this.program)
		this.getData()
	}
}
</script>